<template>
  <el-dialog
      center
      :title="title"
      :visible.sync="dialogVisible" width="700px">
    <div class="content" :class="{'content-active':isPerfect !== 2}">
      <div class="details" v-if="isPerfect === 2">
        <div style="display: flex;">
          <el-image style="width:90px; height:90px;" :src="imgUrl"></el-image>
          <div class="right">
            <div class="name">{{ form.singer_name }}</div>
            <div style="display: flex;align-items: center;margin-bottom: 5px;">
              <div class="form-item" style="margin-right: 30px;">
                <div class="label">类型：</div>
                <p>{{ singerType[form.singer_type] }}</p></div>
              <div class="form-item">
                <div class="label">活跃地区：</div>
              <p>{{ activeArea[form.area] }}</p></div>
            </div>
            <div class="form-item">
              <div class="label">角色：</div>
            <p style="max-width: 425px;">{{ roleFilters(form.singer_role) }}</p></div>
          </div>
        </div>
        <div class="remarks">
          {{ form.singer_desc }}
        </div>
      </div>
      <el-form class="form1" ref="form1" v-if="isPerfect !== 2" label-width="80px" :rules="rules" :model="form">
        <el-row>
          <el-col :span="5">
            <el-image :src="imgUrl" style="width: 90px;height: 90px;">
              <el-image slot="error" :src="require('@/assets/artist-logo.png')" style="width: 90px;height: 90px;">
              </el-image>
            </el-image>
          </el-col>
          <el-col :span="7">
            <p style="width: 200px;height: 44px;font-size: 12px;font-family: PingFang SC, PingFang SC-Regular;font-weight: 400;text-align: LEFT;color: #999999;line-height: 22px;">
              请上传正方形，尺寸为1:1的艺人封面
              图片需为jpg、jpeg、png格式
            </p>
            <hiCosUpload :cover-size="1000" :cover="true" accept=".jpg,.jpeg,.png" :drag="false" v-model="form.cover_id" :kind="4"
                         file-type="contract">
              <el-button size="small" class="upload-demo-but" type="primary">{{
                  imgUrl ? '重新上传' : '上传艺人封面'
                }}
              </el-button>
            </hiCosUpload>
          </el-col>
        </el-row>
        <el-row :gutter="30" style="margin-top: 35px;">
          <el-col :span="12">
            <el-form-item label="艺人名" prop="singer_name">
              <el-input placeholder="请输入艺人名称" v-model="form.singer_name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="艺人类型" prop="singer_type">
              <el-select placeholder="请选择艺人类型" v-model="form.singer_type">
                <el-option v-for="(val,key) in singerType" :key="key" :value="Number(key)" :label="val"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="30">
          <el-col :span="12">
            <el-form-item label="艺人角色" prop="singer_role">
              <el-select placeholder="请选择艺人角色" v-model="form.singer_role" multiple>
                <el-option v-for="(val,key) in singerRole" :key="key" :value="key" :label="val"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="活跃地区" prop="area">
              <el-select placeholder="请选择活跃地区" v-model="form.area">
                <el-option v-for="(val, key) in activeArea" :key="key" :value="key" :label="val"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-form v-if="isPerfect !== 2" ref="form2" label-position="top" label-width="80px" :rules="rules" :model="form">
        <el-form-item label="个人简介" prop="singer_desc" class="el-form-textarea">
          <el-input
              v-model="form.singer_desc"
              :maxlength="1000"
              type="textarea"
              show-word-limit
              placeholder="请输入艺人个人简介">
          </el-input>
        </el-form-item>
      </el-form>
      <el-form ref="form3" label-width="90px" :rules="rules" :model="form">
        <el-form-item label="Q音主页" prop="qq_music_homepage">
          <el-input v-model="form.qq_music_homepage" placeholder="请输入艺人QQ音乐主页地址" :maxlength="400"></el-input>
        </el-form-item>
        <el-form-item label="网易云主页">
          <el-input v-model="form.wangyi_homepage" placeholder="请输入艺人网易云主页地址" :maxlength="400"></el-input>
        </el-form-item>
        <el-form-item label="抖音主页">
          <el-input v-model="form.douyin_homepage" placeholder="请输入艺人抖音主页地址" :maxlength="400"></el-input>
        </el-form-item>
        <el-form-item label="汽水主页">
          <el-input v-model="form.qishui_homepage" placeholder="请输入艺人抖音主页地址" :maxlength="400"></el-input>
        </el-form-item>
        <el-row :gutter="30">
          <el-col :span="12">
            <el-form-item label="Q音粉丝量">
              <el-input v-model="form.qq_music_fans" placeholder="请输入Q音粉丝量">
                <i style="font-style: normal;
                            font-size: 14px;
                            font-family: PingFang SC, PingFang SC-Regular;
                            font-weight: 400;
                            text-align: RIGHT;
                            color: #333333;"
                   slot="suffix"
                >
                  万
                </i>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="酷狗粉丝量">
              <el-input v-model="form.kugou_fans" placeholder="请输入酷狗粉丝量">
                <i style="font-style: normal;
                            font-size: 14px;
                            font-family: PingFang SC, PingFang SC-Regular;
                            font-weight: 400;
                            text-align: RIGHT;
                            color: #333333;"
                   slot="suffix"
                >
                  万
                </i>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="30">
          <el-col :span="12">
            <el-form-item label="抖音粉丝量">
              <el-input v-model="form.douyin_fans" placeholder="请输入Q音粉丝量">
                <i style="font-style: normal;
                            font-size: 14px;
                            font-family: PingFang SC, PingFang SC-Regular;
                            font-weight: 400;
                            text-align: RIGHT;
                            color: #333333;"
                   slot="suffix"
                >
                  万
                </i>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button class="determine" @click="onsubmit" :loading="submitLoading">确 定</el-button>
  </span>
  </el-dialog>

</template>

<script>
import store from "@/store";
import hiCosUpload from '@/components/HiCosUpload';

export default {
  props: {},
  name: "artistPopup",
  components: {
    hiCosUpload
  },
  data() {
    return {
      imgUrl: undefined,
      isPerfect: undefined,
      title: '',
      dialogVisible: false,
      form: {},
      uploadApi: process.env.VUE_APP_BASE_API + '/api/file/upload' + '/upload/saveFile',
      headers: {
        'X-EFRESH-SECRET': store.state.secret,
        'X-EFRESH-SESSION': store.state.session
      },
      rules: {
        singer_name: {required: true, message: '请输入艺人名称', trigger: 'blur'},
        singer_type: {required: true, message: '请输入艺人类型', trigger: 'blur'},
        singer_role: {required: true, message: '请输入艺人角色', trigger: 'blur'},
        area: {required: true, message: '请输入活跃地区', trigger: 'blur'},
        singer_desc: {required: true, message: '请输入简介', trigger: 'blur'},
      },
      submitLoading: false
    }
  },
  created() {
    this.emptyForm();
  },
  computed: {
    singerType() {
      return this.$store.state.singer ? this.$store.state.singer.singer_type : {}
    },
    singerRole() {
      return this.$store.state.singer ? this.$store.state.singer.singer_role : {}
    },
    activeArea() {
      return this.$store.state.singer ? this.$store.state.singer.area : {}
    }
  },
  methods: {
    /**
     *@param {String} title - 标题
     *@param {Number} isPerfect - 新增：0，编辑：1 完善：2
     *@param {Number} id - id字段
     *@return void,
     * */
    initializeFn(title, isPerfect, id,) {
      this.emptyForm();
      this.dialogVisible = true;
      this.title = title;
      this.isPerfect = isPerfect;
      if (isPerfect !== 0) {
        this.singerShowFn(id)
      }
    },
    //初始化弹窗
    emptyForm() {
      this.form = {
        id: undefined,
        singer_name: undefined,//艺人名
        cover_id: undefined,//上传艺人封面文件ID
        singer_type: undefined,//类型（单选） 参考常量歌手类型
        singer_role: undefined,//角色（多选） 参考常量歌手角色
        area: undefined,//活跃区域（单选） 参考常量活跃地区
        qq_music_homepage: undefined,//Q音主页
        wangyi_homepage: undefined,//网易云主页
        douyin_homepage: undefined,//抖音主页
        qq_music_fans: undefined,//Q音粉丝量（单位万）
        kugou_fans: undefined,//酷狗粉丝量
        douyin_fans: undefined,//抖音粉丝量
        singer_desc: undefined,//简介
        qishui_homepage:undefined,
      }
    },
    onsubmit() {
      if (!this.form.cover_id) return this.$toast('请上传艺人封面');
      let p = {}
      let {
        id,
        singer_name,
        singer_type,
        singer_role,
        area,
        qq_music_homepage,
        wangyi_homepage,
        douyin_homepage,
        qq_music_fans,
        kugou_fans,
        douyin_fans,
        singer_desc,
        cover_id,
        qishui_homepage
      } = this.form;
      singer_role = singer_role.join(',')
      p = {
        id,
        singer_name,
        singer_type,
        singer_role,
        area,
        qq_music_homepage,
        wangyi_homepage,
        douyin_homepage,
        qq_music_fans,
        kugou_fans,
        douyin_fans,
        singer_desc,
        cover_id,
        qishui_homepage
      }
      if (this.isPerfect === 0 || this.isPerfect === 1) {
        this.$refs.form1.validate(valid => {
          if (valid) {
            this.$refs.form2.validate(valid => {
              if (valid) {
                if (valid) {
                  this.submitLoading = true
                  if (this.isPerfect === 0) {
                    this.$post('/api/issue/universal/singer/create', p).then(this.refreshList).catch(() => this.submitLoading = false)
                  } else {
                    this.$post('/api/issue/universal/singer/update', p).then(this.refreshList).catch(() => this.submitLoading = false)
                  }
                } else {
                  console.log('验证不成功')
                }
              }
            })
          }
        })
      } else {
        this.$refs.form3.validate(valid => {
          if (valid) {
            this.submitLoading = true
            this.$post('/api/issue/universal/singer/update', p).then(this.refreshList).catch(() => this.submitLoading = false)
          } else {
            console.log('验证不成功')
          }
        })
      }
    },
    singerShowFn(id) {
      this.$post('/api/issue/universal/singer/show', {id}).then(r => {
        r.data.singer_role = r.data.singer_role.split(',');
        this.form = r.data
      })
    },
    refreshList(r) {
      this.submitLoading = false
      if (r.code === 0 && r.data !== null) {
        this.$emit('determine', r.data.singer_id);
        this.dialogVisible = false;
        this.imgUrl = undefined;
      } else {
        this.$toast(r.msg);
      }
    },
    roleFilters(val) {
      if (!val) return
      let i = []
      if (this.singerRole) {
        val.forEach(item => {
          i.push(this.singerRole[item]);
        })
      }
      return i.join(',');
    }
  },
  watch: {
    'form.cover_id'(val) {
      if (val !== undefined) {
        this.$post('/api/issue/universal/upload/previewFile', {file_id: val, type: 0}).then(r => {
          this.imgUrl = r.data.url;
        })
      }
    },
    dialogVisible(val) {
      if (!val) {
        this.imgUrl = undefined;
        this.emptyForm();
      }
    }
  },
}
</script>

<style scoped lang="scss">
::v-deep .el-dialog {
  padding: 40px 10px;
  border-radius: 10px;

  .el-dialog__header {
    padding: {
      top: 0;
      bottom: 40px;
    };

    .el-dialog__title {
      font-size: 18px;
      font-family: PingFang SC, PingFang SC-Semibold;
      font-weight: 600;
      color: #222222;
    }
  }

  .el-dialog__body {
    padding: 0;
  }

  .el-dialog__footer {
    padding: {
      top: 40px;
      bottom: 0;
    };

    .el-button {
      width: 188px;
      height: 42px;
      background: #f7f7f7;
      border-radius: 3px;
      border: none;
      font-size: 15px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #666666;
      letter-spacing: 0.01px;
    }

    .determine {
      background-color: rgba(255, 0, 77, .5);
      color: #ffffff;
    }
  }
}

.content {
  box-sizing: border-box;
  padding: 0 45px;

  p {
    margin: 0;
  }

  .details {
    margin-bottom: 20px;

    .right {
      margin-left: 30px;
      .name {
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 600;
        text-align: LEFT;
        color: #555555;
        margin-bottom: 5px;
      }

      .form-item {
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: LEFT;
        color: #999999;
        display: flex;
      }
    }

    .remarks {
      margin-top: 12px;
      width: 590px;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: LEFT;
      color: #999999;
      line-height: 20px;
    }
  }

  ::v-deep .el-form {
    .el-form-item {
      border-bottom: 1px solid #e8e8e8;

      .el-form-item__label {
        height: 35px;
        font-size: 14px;
        text-align: LEFT;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #333333;
      }
    }

    .el-form-textarea {
      border: none;
    }
  }

  ::v-deep .el-input__inner {
    border: none;
    height: 35px;
    background: #ffffff;
    padding-left: 15px;
    box-sizing: border-box;
    &::placeholder{
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #aaaaaa;
    }
  }

  ::v-deep .el-textarea {
    text-align: right;
  }

  ::v-deep .el-textarea__inner {
    height: 106px;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    &::placeholder{
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #aaaaaa;
    }
  }
}

::v-deep .form1 {
  .el-input__inner{
    text-align:right;
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    color: #aaaaaa;
  }
}

.content-active {
  height: 402px;
  overflow-x: hidden;
  padding-right: 20px;
}

.content::-webkit-scrollbar {
  width: 4px;
}

// 设置滚动条整体样式

.content::-webkit-scrollbar-thumb {
  background: #cccccc;
  border-radius: 10px;
}

.upload-demo-but {
  margin-top: 8px;
  width: 104px;
  height: 30px;
  background: #ffffff;
  border: 0.5px solid #dedede;
  border-radius: 4px;
  font-size: 12px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  color: #999999;
  box-sizing: border-box;
}
</style>
