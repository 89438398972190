<template>
  <div style="display: inline-flex;flex-direction: column">
    <el-upload
        :disabled="disabled"
        :class="{big}"
        action="/"
        :accept="accept"
        :drag="drag"
        :limit="1"
        :before-upload="upload">
      <slot>
        <div class="upload">
          <div class="default-text">
            <el-image :src="uploadImage" style="width: 16px;height: 16px;margin-right: 8px"></el-image>{{ text }}
          </div>
        </div>
      </slot>
    </el-upload>
    <div class="song-details" v-if="progress && !cover">
      <div class="song-name" v-if="isModify" >
        <el-image :src="selectIcon" style="width: 14px;height: 14px;margin-right: 6px;"></el-image>
        <div class="name">{{filename}}</div>
        <div class="right-icon" v-if="!routerQuery">
          <el-image @click="modifyFn" :src="require('@/assets/modify-issue-icon.png')" style="width: 14px;height: 14px; margin-right: 17px;" ></el-image>
          <el-image @click="handleDelete()" :src="require('@/assets/delete-issue-icon.png')" style="width: 14px;height: 14px;" ></el-image>
        </div>
      </div>
      <div class="song-name-modify" v-else>
        <el-image :src="selectIcon" style="width: 14px;height: 14px;margin-right: 6px;"></el-image>
        <div class="name">
          <el-input v-model="fileNameArray[0]" >
            <span class="el-suffix" slot="suffix">{{fileNameArray[1]}}</span>
          </el-input>
        </div>
        <div class="right-icon" v-if="!routerQuery">
          <el-image @click="confirm" :src="require('@/assets/determine-issue-icon.png')" style="cursor: pointer; width: 14px;height: 14px; margin-right: 17px;" ></el-image>
          <el-image @click="isModify = true;fileNameArray = [];" :src="require('@/assets/colse-issue-icon.png')" style="width: 14px;height: 14px;cursor: pointer;" ></el-image>
        </div>
      </div>
      <el-progress :percentage="progress"  v-if="progress !== 100" ></el-progress>
    </div>
  </div>
</template>

<script>
import moment from "moment"
import COS from "cos-js-sdk-v5"
import uploadImage from "@/assets/upload/upload.png"
import okImage from "@/assets/upload/ok.png"
import editImage from "@/assets/upload/edit.png"
import deleteImage from "@/assets/upload/delete.png"
import issueAudioIcon from  "@/assets/issue-audio-icon.png"
import SparkMD5 from 'spark-md5'
import songFileIcon from  '@/assets/song-file-icon.png'
import md5 from "md5"

export default {
  name: "HiCosUpload",
   computed:{
     routerQuery(){
       return this.$route.name === 'IssueQuery'
     },
     selectIcon(){
       if(this.kind === 7 || this.kind === 8 || this.kind === 8 || this.kind === 9 || this.kind === 10 || this.kind === 11 || this.kind === 12){
         return this.issueAudioIcon
       } else {
         return  this.songFileIcon
       }
     }
   },
  props: {
    coverName: {
      type: String,
      default: undefined
    },
    singers:{
      type:Array,
      default:()=>[]
    },
    accept: {
      type: String,
      default: '*/*'
    },
    text: {
      type: String,
      default: '点击或拖拽'
    },
    noInfo: {
      type: Boolean,
      default: false
    },
    drag: {
      type: Boolean,
      default: true
    },
    big: {
      type: Boolean,
      default: false
    },
    cover: {
      type: Boolean,
      default: false
    },
    value: {
      type: Number,
      default: undefined
    },
    fileType: {
      type: String,
      default: 'other' // 'contract'
    },
    /**
     * 文件类别【3歌词txt，4封面，7片段-主歌，8片段-其他，10伴奏-无和声，11原曲，12伴奏-和声，14封面源文件，其他的0】
     */
    kind: {
      type: Number,
      default: 0
    },
    disabled:{
      type:Boolean,
      default:false,
    },
    baseInfo:{
      type:Object,
      default:()=>{}
    },
    size: {
      type: Array,
      default: () => []
    },
    showLoading: {
      type: Boolean,
      default: true
    },
    md5: {
      type: String,
      default: undefined
    }
  },

  data () {
    return {
      uploadImage,
      okImage,
      editImage,
      deleteImage,

      progress: 0,
      filename: '',
      isModify :true,
      fileNameArray:[],
      singersAry:[],
      issueAudioIcon,
      songFileIcon,

      loading: undefined
    }
  },

  methods: {
    handleDelete(){
      this.progress = 0
      this.filename = ''
      this.$emit('input', 0)
    },
    onProgress (progressData) {
      if (this.showLoading && this.loading) this.loading.close()

      this.progress = parseInt((progressData["percent"] * 100).toFixed(0))
    },
    saveFile(data){
      this.$post('/api/issue/universal/upload/saveFile', data).then(r => {
        this.$emit('input', r.data.file_id, data.name)
      }).catch(e => {
        console.log(e)
        this.progress = 0
      })
    },
    async upload(file){
      let fileMd5 = ''
      const fileReader = new FileReader()
      fileReader.readAsBinaryString(file)
      const isSameFile = await new Promise((resolve) => {
        fileReader.onload=(e)=>{
          fileMd5 = SparkMD5.hashBinary(e.target.result)
          console.log(fileMd5, this.md5)
          if (fileMd5 === this.md5) {
            resolve(true)
          } else {
            resolve(false)
          }
        }
      })

      if(isSameFile){
        return false
      }

      if(this.cover){
        let res =  await new Promise((resolve)=>{
          let _URL = window.URL || window.webkitURL;
          let img = new Image();
           img.src = _URL.createObjectURL(file);
           img.onload = function (){
             resolve({w:img.width, h:img.height})
           }
        })
        if(res.w !== res.h) {
          this.$popup({msg: `请上传正方形，尺寸为1:1的图片`})
          return false
        }
        // console.log(this.size.indexOf(res.w))
        // if(this.size.indexOf(res.w) === -1) {
        //   this.$popup({msg: `请上传正方形，尺寸为2000x2000或者3000x3000`, duration:5000})
        //   return false
        // }

      }
      if (this.showLoading) this.loading = this.$loading({
        lock: true,
        text: '文件上传中。。。',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.filename = !this.cover ? this.setName(file.name) : file.name;
      const config = {
        contract: {
          type: 'abc',
          bucket: process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'development' ? 'legal-test-1305250541' : 'legal-online-1305250541'
        },
        other: {
          type: 'material',
          bucket: 'legal-1305250541'
        }
      }

      let Region = "ap-shanghai";
      let dir = 'albums/material/' + moment().format('YYYY-MM-DD/x');
      let bucket = config[this.fileType].bucket;
      let type= config[this.fileType].type;
      const that = this
      let cos = new COS({
        async getAuthorization(options, callback) {
          let url = '/api/issue/universal/upload/getCosToken'; // 这里替换成您的服务接口地址
          await that.$post(url, {
            dir,
            type
          }).then(r => {
            let data = r.data
            let credentials = r.data.credentials

            if (!data || !credentials) return console.error('credentials invalid');
            callback({
              TmpSecretId: credentials.tmpSecretId,
              TmpSecretKey: credentials.tmpSecretKey,
              XCosSecurityToken: credentials.sessionToken,
              StartTime: data.startTime, // 时间戳，单位秒，如：1580000000，建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
              ExpiredTime: data.expiredTime, // 时间戳，单位秒，如：1580000900
              ScopeLimit: true,
            });
          })
        }
      });

      //bucket,合同测试使用"legal-test-1305250541"，线上使用"legal-online-1305250541"
      //其他歌曲相关物料使用"legal-1305250541"

      const fileNameArray = file.name.split('.')
      const fileNameArrayLength = fileNameArray.length
      let fileName = ''
      for (let i = 0;i < fileNameArrayLength;i++) {
        fileName += fileNameArray[i]
      }
      const fileExt = fileNameArray[fileNameArrayLength - 1]

      let file_key = dir + '/' + md5(fileName)+ '.' + fileExt
      cos.sliceUploadFile({
        Bucket: bucket,
        Region: Region,
        Key: file_key,
        StorageClass: 'STANDARD',    //'STANDARD_IA'  ,无损音频使用'STANDARD_IA'
        // ACL:'public-read',
        Body: file,
        onProgress: this.onProgress,
      }, (err, data) => {
        if (data) {
          this.saveFile({
            type: that.fileType === 'contract' || this.cover ? 0 : 1,
            name: this.setName(file.name) ,
            kind: that.kind,
            dir,
            url: data.Key,
            location: data.Location,
            mime: file.type || 'file',
            size: file.size,
            md5: fileMd5
          })
        } else {
          this.$popup({msg: "文件上传失败"});
        }
      });
      return false
    },
    modifyFn(){
     this.isModify =false;
      let index = this.filename.lastIndexOf('.');
     this.fileNameArray[0] = this.filename.substring(0,index)
     this.fileNameArray[1] = this.filename.substring(index,this.filename.length)
    },
    confirm(){
      this.$post('/api/issue/universal/upload/renameFile',{
        type:this.fileType === 'contract' || this.cover ? 0 : 1,
        id:this.value,
        name:this.fileNameArray[0] + this.fileNameArray[1]
      }).then(r => {
        if(r.code === 0){
          this.filename = this.fileNameArray[0] + this.fileNameArray[1]
          this.fileNameArray = [];
          this.isModify = true;
        }
      })
    },
    setName(val){
      let index = val.lastIndexOf('.');
      let m = val.substring(index, val.length)
      if (this.coverName) return this.coverName + m
      if (this.cover) return val
      let type_str = '';
      let singers = []
      switch (this.kind){   //10,11,12,14,3,7,8
        case 3:type_str = '歌词';break;
        case 7:type_str = '片段-主歌';break;
        case 8:type_str = '片段-其他';break;
        case 10:type_str = '伴奏-无和声';break;
        case 11:type_str = '原曲';break;
        case 12:type_str = '伴奏-和声';break;
        case 14:type_str = '封面源文件';break;
        default:type_str = '文件'
      }
      singers = this.baseInfo.singer_sources ? this.baseInfo.singer_sources.map(e => e.singerName) : [];

      const songVersion = this.baseInfo.song_version ? ' (' + this.baseInfo.song_version + ')' : ''

      if(!this.baseInfo.song_name) return  val
      else if (singers.length > 0) return this.baseInfo.song_name + songVersion + '-' + singers.join(',') + '-' + type_str + m
      else return this.baseInfo.song_name + songVersion + '-' + type_str + m
    }
  },
  watch:{
    value(val){
      if(val && !this.filename){
        this.$post('/api/issue/universal/upload/previewFile',{type:this.fileType === 'other' ? 1 : 0,file_id:val}).then(r => {
          if(r.code === 0){
            this.filename = r.data.name;
            this.progress = 100;
          }
        })
      } else if(val === 0) {
        this.handleDelete()
      }
    },
  }
}
</script>

<style scoped lang="scss">
.upload{
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  background-color: #fcfcfc;
  height: 60px;
}
::v-deep .el-upload-dragger{
  height: 60px;
  display: flex;
  align-items: center;
}
.big ::v-deep .upload{
  height: 100px;
}
.big ::v-deep .el-upload-dragger{
  height: 100px;
  width: 100%;
}
.big ::v-deep .el-upload{
  width: 100%;
}
.upload-progress{
  width: 0;
  height: 60px;
  position: absolute;
  background: rgba(107,175,11,0.30);
}
.default-text{
  font-size: 12px;
  color: #ff004d;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.upload-content{
  padding: 17px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.file-name, .action{
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #333333;
  overflow: hidden;
  width: 200px;
  display: block;
  text-overflow: ellipsis;
  text-align: left;
}
.song-details{
  /*border: 1px solid;*/
  padding-top: 12px;
}
.song-details >.song-name{
   display: flex;
  justify-content:center;
  align-items: center;
  height: 29px;
  padding:0 4px;
  .right-icon{
    opacity: 0;
    display: flex;
    align-items: center;

  }
  &:hover{
     background-color: rgba(255,0,77,.1);
     cursor: pointer;
      .right-icon{
        opacity: 1;
      }

  }
}
.song-details > .song-name-modify{
  display: flex;
  justify-content:center;
  align-items: center;
  height: 29px;
  padding:0 4px;
  flex: 1;
  border: 1px solid #cecece;
  .right-icon{
    display: flex;
    align-items: center;
  }
}

.song-details .name {
  flex: 1;
  ::v-deep .el-input{
    .el-input__suffix{
      display: block;

    }
    .el-input__inner{
      border: none;
      height: 29px;
    }
  }

}
::v-deep .el-progress{
  width: 100%;
  display: flex;
  align-items: center;
  .el-progress-bar{
    margin-right: -25px!important;
    padding-right: 27px!important;
  }
  .el-progress-bar__outer{
    height: 2px !important;
  }
  .el-progress__text{
    font-size: 10px!important;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    color: #333333;
    width: 20px;
  }

}

</style>
